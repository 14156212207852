//Servicios
$(document).on('click','.abreServicioModal', function(){
    var id = this.id.substr(9,25);
    console.log(id);
    $("#_method").val("PUT");
    $("#formServicio").attr('action',"servicios/"+id);
    $("#btnDeleteServicio").css("display","block");
    $("#btnDeleteServicio").attr('onclick',"document.getElementById('delete-"+id+"').submit()");
    $(".campo").val("");
    $.ajax({
        url: "servicios/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaServicioLabel").html("Edición de servicio "+data['nombre']);
            $.each(data,function(index,value){
                $("#"+index.toLowerCase()).val(value);
            });
        }
    }); 
});
$(document).on('click','.nuevoServicioModal', function(){
    $("#_method").val("POST");
    $("#formServicio").attr('action',"servicios");
    $("#fichaServicioLabel").html("Creación de nuevo servicio");
    $("#btnDeleteServicio").css("display","none");
    $("#btnReciboServicio").css("display","none");
    $(".campo").val("");
});