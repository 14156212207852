//Clientes
const Choices = require('./choices.js');
const fecha = new Date();
const ultimo_dia_mes = [31,28,31,30,31,30,31,31,30,31,30,31];
$(document).on('click','.abreClienteModal', function(){
    $("#fichaCliente").modal("show");
    var id = this.id.substr(8,25);
    $("#_method").val("PUT");
    $("#formCliente").attr('action',"clientes/"+id);
    $(".btnDeleteCliente").css("display","block");
    $(".btnDeleteCliente").attr('id',"delete-"+id);
    $("#actividades").empty();
    $(".campo").val("");
    $.ajax({
        url: "clientes/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaClienteLabel").html("Edición de cliente "+data['nombre']);
            $.each(data,function(index,value){
                $("#"+index.toLowerCase()).val(value);
            });
        }
    }); 
});
$(document).on('click','.nuevoClienteModal', function(){
    $("#_method").val("POST");
    $("#formCliente").attr('action',"clientes");
    $("#fichaClienteLabel").html("Creación de nuevo cliente");
    $(".btnDeleteCliente").css("display","none");
    $(".campo").val("");    
})
//HERRAMIENTAS CARDS

$(document).on('click','.desplegable',function(){
    var icon = $(this);
    $.each(icon.attr('class').split(' '),function(index,value){
        if(value == 'bi-caret-up'){
            $(".registro-historial-items-"+icon.parent().parent().parent().parent().attr('id').substr(18,100)).removeClass('d-none');
            icon.addClass('bi-caret-down-fill');
            icon.removeClass('bi-caret-up-fill');
        }
        if(value == 'bi-caret-down'){
            $(".registro-historial-items-"+icon.parent().parent().parent().parent().attr('id').substr(18,100)).addClass('d-none');
            icon.addClass('bi-caret-up-fill');
            icon.removeClass('bi-caret-down-fill');
        }
    });
});

$(document).on('click','.btnDeleteCliente',function(){
    if(confirm("Estás seguro que deseas eliminar a este cliente?") == true){
        $("#form"+this.id).submit();
    }
});

//FUNCIONES
