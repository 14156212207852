$(document).on('click','.abreFacturaModal', function(){
    var id = this.id.substr(8,25);
    $("#_method").val("PUT");
    $("#formDocumento").attr('action',"facturas/"+id);
    $("#btnDeleteDocumento").css("display","block");
    $("#btnDeleteDocumento").attr('onclick',"document.getElementById('delete-"+id+"').submit()");
    $("#nombre-documento").html("Factura");
    $("#label-numero-documento").html("N.Factura:");
    $("#tipo-documento").val("factura");
    $("#container-irpf-documento").show();
    $(".campo").val("");
    $.ajax({
        url: "facturas/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaFacturaLabel").html("Edición de factura");
            //Serial
            $(".num-documento").val(data['serial']);
            //Emisor
            if(data['empresa']['logo'] == null){
                $(".logo-documento img").attr('src',"storage/logo/nologo.jpg");
            }else{
                $(".logo-documento img").attr('src',"storage/"+data['empresa']['logo']);
            }
            $(".nombre-emisor").html(data['empresa']['nombre']);
            $(".cif-emisor").html("NIF: "+data['empresa']['cif']);
            $(".direccion-emisor").html(data['empresa']['direccion']);
            $(".poblacion-emisor").html(data['empresa']['codigo_postal']+" "+data['empresa']['poblacion']);
            $(".cliente-id").html(data['cliente']['id']);
            $("#cliente-id").val(data['cliente']['id']);
            $("#empresa-id").val(1);       
            //Receptor
            $(".nombre-receptor").html(data['cliente']['nombre']);
            $(".cif-receptor").html("NIF: "+data['cliente']['cif']);
            $(".direccion-receptor").html(data['cliente']['direccion']);
            $(".poblacion-receptor").html(data['cliente']['codigo_postal']+" "+data['cliente']['poblacion']); 
            //Fecha
            $(".fecha-documento").val(data['fecha_emision']);
            //Observaciones
            $("#observaciones").val(data['observaciones']);
            //Items
            $(".items-documento").empty();
            $(".items-documento").append(`
                <div class="cabecera-items" style='position:sticky; top:0; background-color:#fff'> 
                    <div class="cabecera-item-documento-cantidad">
                        <p class='m-auto text-center mb-1 fw-bolder'>Cant</p>
                    </div>
                    <div class="cabecera-item-documento-nombre"'>
                        <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
                    </div>
                    <div class="cabecera-item-documento-precio">
                        <p class='m-auto text-center mb-1 fw-bolder'>Precio</p>
                    </div>
                    <div class="cabecera-item-documento-descuento">
                        <p class='m-auto text-center mb-1 fw-bolder'>Descuento %</p>
                    </div>
                    <div class="cabecera-item-documento-importe">
                        <p class='m-auto text-center mb-1 fw-bolder'>Importe</p>
                    </div>
                    <div class="cabecera-item-documento-borrar"></div>
                </div>
            `);
            $.each(data['lineas'], function(index,value){
                $(".items-documento").append(`
                    <div class="registro-items" style='position:sticky; top:0; background-color:#fff'> 
                        <input name="input_item_id[]" class="d-none input-item-id" type='text' value='`+value.servicio_id+`'>
                        <div class="registro-item-documento-cantidad">
                            <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad" type='number' step='1.00' value='`+value.cantidad+`'>
                        </div>    
                        <div class="registro-item-documento-nombre">
                            <input type="text" name='input_item_concepto[]' class='form-control m-auto text-center input-item-concepto' value='`+value.concepto+`'>
                        </div>
                        <div class="registro-item-documento-precio">
                            <input name="input_item_precio[]" class="form-control m-auto text-center input-item-precio" type='number' step='0.01' value='`+value.precio+`'>
                        </div>
                        <div class="registro-item-documento-descuento">
                            <input name="input_item_descuento[]" class="form-control m-auto text-center input-item-descuento" type='number' step='0.01' value='0'>
                        </div>
                        <div class="registro-item-documento-importe">
                            <input type="number" step="0.01" name="input_item_importe[]" class='form-control m-auto text-center input-item-importe' step='0.01' value='`+value.total+`'>
                        </div>
                        <div class="registro-item-documento-borrar">
                            <a title='Borrar' class='borrar-item' href='#'><i class="bi bi-trash3-fill icono-boton"></i></a>
                        </div>
                    </div>
                `);
            });
            //Totales
            $("#subtotal-documento").val(data['subtotal']);
            $("#irpf-documento").val(data['irpf']);
            $("#iva-documento").val(data['iva']);
            $("#total-documento").val(data['total']);
        }
    }); 
});
$(document).on('click','.nueva-factura', function(){
    var id = this.id.substr(14,25);
    $("#_method").val("POST");
    $("#formDocumento").attr('action',"facturas");
    $("#fichaDocumentoLabel").html("Creación de nueva factura");
    $("#btnDeleteDocumento").css("display","none");
    $(".campo").val("");
    $("#nombre-documento").html("Factura");
    $("#label-numero-documento").html("N.Factura:");
    $("#tipo-documento").val("factura");
    $("#container-irpf-documento").show();
    //Emisor
    $.ajax({
        url: "empresa/1",
        type: "GET",
        dataType: "json",
        success: function(data){
            if(data['logo'] == null){
                $(".logo-documento img").attr('src',"storage/logo/nologo.jpg");
            }else{
                $(".logo-documento img").attr('src',"storage/"+data['logo']);
            }
            $(".nombre-emisor").html(data['nombre']);
            $(".cif-emisor").html("NIF: "+data['cif']);
            $(".direccion-emisor").html(data['direccion']);
            $(".poblacion-emisor").html(data['codigo_postal']+" "+data['poblacion']);
            $(".cliente-id").html(id);
            $("#cliente-id").val(id);
            $("#empresa-id").val(1);
            
        }
    });
    //Receptor
    $.ajax({
        url: "clientes/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $(".nombre-receptor").html(data['nombre']);
            $(".cif-receptor").html("NIF: "+data['cif']);
            $(".direccion-receptor").html(data['direccion']);
            $(".poblacion-receptor").html(data['codigo_postal']+" "+data['poblacion']);       
        }
    });
    //Fecha
    var fecha = new Date();
    var dia = fecha.getDate();
    var mes = fecha.getMonth()+1;
    var anyo = fecha.getFullYear();
    $(".fecha-documento").val(anyo+"-"+("00" + mes).substr(-2,2)+"-"+("00" + dia).substr(-2,2));
    //Items factura
    $(".items-documento").empty();
    $(".items-documento").append(`
        <div class="cabecera-items" style='position:sticky; top:0; background-color:#fff'> 
            <div class="cabecera-item-documento-cantidad">
                <p class='m-auto text-center mb-1 fw-bolder'>Cant</p>
            </div>
            <div class="cabecera-item-documento-nombre"'>
                <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
            </div>
            <div class="cabecera-item-documento-precio">
                <p class='m-auto text-center mb-1 fw-bolder'>Precio</p>
            </div>
            <div class="cabecera-item-documento-descuento">
                <p class='m-auto text-center mb-1 fw-bolder'>Descuento %</p>
            </div>
            <div class="cabecera-item-documento-importe">
                <p class='m-auto text-center mb-1 fw-bolder'>Importe</p>
            </div>
            <div class="cabecera-item-documento-borrar"></div>
        </div>
    `);
    //Serial
    $.ajax({
        url: "facturas/lastserial",
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            $(".num-documento").val(data);
        }
    });
});
$(document).on('click','.historial-facturas',function(){
    var id = this.id.substr(19,20);
    $.ajax({
        async: false,
        url: "clientes/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            $("#historialLabel").html("Historial de facturas de "+data['nombre']);
            $("#historial-lista").empty();
            $("#historial-lista").append(`
                <div class="registro-historial" style='position:sticky; top:0; background-color:#fff'> 
                    <div class='d-flex flex-column w-100'>
                        <div class='d-flex flex-row align-items-center w-100'>
                            <div style='width:2%'>
                                            
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'># Factura</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Fecha</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Subtotal</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>IRPF</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>IVA</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Total</p>
                            </div>
                            <div style='width:2%'>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            `);
            $.each(data.facturas,function(index,value){
                var html = `
                    <div class="registro-historial" id="registro-historal-`+value['id']+`"> 
                        <div class='d-flex flex-column w-100'>
                            <div class='d-flex flex-row align-items-center w-100'>
                                <div style='width:2%'>
                                    <i class="bi bi-caret-down-fill desplegable"></i>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['serial']+`</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'><i class="bi bi-calendar-event me-1"></i>`+value['fecha_emision']+`</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['subtotal']+`€</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['irpf']+`€</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['iva']+`€</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['total']+`€</p>
                                </div>
                                <div style='width:2%'>
                                    <i class="bi bi-eye-fill ver-factura"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                `;  
                $("#historial-lista").append(html);
                $("#historial-lista").append(`
                    <div class="registro-historial-items registro-historial-items-`+value['id']+`"> 
                        <div class='d-flex flex-column w-100'>
                            <div class='d-flex flex-row align-items-center w-100'>
                                <div class='w-100' style="font-size:12px">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Cantidad</p>
                                </div>
                                <div class='w-100' style="font-size:12px">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Concepto</p>
                                </div>
                                <div class='w-100' style="font-size:12px">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Precio</p>
                                </div>
                                <div class='w-100' style="font-size:12px">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Descuento</p>
                                </div>
                                <div class='w-100' style="font-size:12px">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Total</p>
                                </div>
                            </div>
                        </div>
                    </div>
                `);
                $.each(value.lineas,function(index2,value2){
                    var html = `
                        <div class="registro-historial-items registro-historial-items-`+value['id']+`"> 
                            <div class='d-flex flex-column w-100'>
                                <div class='d-flex flex-row align-items-center w-100'>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1' style="font-size:12px">`+value2.cantidad+`ud</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1' style="font-size:12px">`+value2.concepto+`</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1' style="font-size:12px">`+value2.precio+`€</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1' style="font-size:12px">`+value2.descuento+`%</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1' style="font-size:12px">`+value2.total+`€</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;  
                    $("#historial-lista").append(html);
                });
            });
        }
    });
});
$(document).on('click','.ver-factura',function(){
    var icon = $(this);
    var id = icon.parent().parent().parent().parent().attr('id').substr(18,100);
    window.open("facturas/imprimir/"+id);
});
$(document).on('click','.enviar-factura',function(){
    var id = this.id.substr(15,25);
    Swal.fire({
        title: "Estás seguro que enviar por email esta factura?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Enviar",
        denyButtonText: 'No enviar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            $.ajax({
                url: "facturas/enviar/"+id,
                type: "GET",
                dataType: "json",
                success: function(data){
                    if(data == true){
                        Swal.fire("La factura se ha enviado correctamente", "", "success");
                    }else{
                        Swal.fire("La factura no se ha podido enviar", "", "error");
                    }
                }
            });
        } else if (result.isDenied) {
          Swal.fire("La factura no se envió", "", "info");
        }
    });
});
//FUNCIONES
