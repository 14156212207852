//ITEMS
$(document).on('click','.addserviciodocumento',function(){
    $("#listaServicios").modal("show");
    $("#listaServiciosLabel").empty();   
    $("#servicios-lista").empty(); 
    $("#listaServiciosLabel").append("Lista de servicios");
    $.ajax({
        url: "servicios/aux/",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#servicios-lista").append(`
                <div class="cabecera-lista" style='position:sticky; top:0; background-color:#fff; border-top:line solid thin'> 
                    <div class='d-flex flex-column w-100'>
                        <div class='d-flex flex-row align-items-center w-100'>
                            <div style='width:75%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
                            </div>
                            <div style='width:15%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Precio</p>
                            </div>
                            <div style='width:10%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Elegir</p>
                            </div>
                        </div>
                    </div>
                </div>
            `);
            $.each(data,function(index,value){
                var html = `
                    <div class="registro-lista"> 
                        <div class='d-flex flex-column w-100'>
                            <div class='d-flex flex-row align-items-center w-100'>
                                <div style='width:75%'>
                                    <p class='m-auto text-center mb-1 capitalise'>`+value['nombre']+`</p>
                                </div>
                                <div style='width:15%'>
                                    <p class='m-auto text-center mb-1'>`+value['precio']+`€</p>
                                </div>
                                <div class="text-center" style='width:10%'>
                                    <input type='checkbox' value='`+value['id']+`'>
                                </div>
                            </div>
                        </div>
                    </div>
                `;  
                $("#servicios-lista").append(html);
            });

        }
    });
});
$(document).on('click',"#confirmaddservicio",function(){
    var servicios = $("#listaServicios input");
    $.each(servicios,function(index,value){
        if($(value).is(':checked')){
            $.ajax({
                url: "servicios/"+$(value).val(),
                type: "GET",
                dataType: "json",
                success: function(data){
                    $(".items-documento").append(`
                        <div class="registro-items" style='position:sticky; top:0; background-color:#fff'> 
                            <input name="input_item_id[]" class="d-none input-item-id" type='text' value='`+data['id']+`'>
                            <div class="registro-item-documento-cantidad">
                                <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad" type='number' step='0.10' value='1'>
                            </div>    
                            <div class="registro-item-documento-nombre">
                                <input type="text" name='input_item_concepto[]' class='form-control m-auto text-center mb-1 input-item-concepto' value='`+data['nombre']+`'>
                            </div>
                            <div class="registro-item-documento-precio">
                                <input name="input_item_precio[]" class="form-control m-auto text-center input-item-precio" type='number' step='0.01' value='`+data['precio']+`'>
                            </div>
                            <div class="registro-item-documento-descuento">
                                <input name="input_item_descuento[]" class="form-control m-auto text-center input-item-descuento" type='number' step='0.01' value='0'>
                            </div>
                            <div class="registro-item-documento-importe">
                                <input type="number" step="0.01" name="input_item_importe[]" class='form-control m-auto text-center input-item-importe mb-1' step='0.01' value='`+data['precio']+`'>
                            </div>
                            <div class="registro-item-documento-borrar">
                                <a title='Borrar' class='borrar-item' href='#'><i class="bi bi-trash3-fill"></i></a>
                            </div>
                        </div>
                    `); 

                    recalculoimporte();
                },
                async: false
            });
        }
    });
    $("#listaServicios").modal('hide');
});
$(document).on('keyup','#filtro-box-servicios',function(){
    var value = $(this).val().toLowerCase();
		$("#servicios-lista  .registro-lista").filter(function() {
			$(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
		});
});
$(document).on('click','.borrar-item',function(){
    $(this).parent().parent().remove();
    recalculoimporte();
});
$(document).on('change','.input-item-precio',function(){
    var valor_precio = $(this).val();
    var valor_descuento       = $(this).parent().parent().find(".input-item-descuento").val();
    var valor_cantidad  = $(this).parent().parent().find(".input-item-cantidad").val();
    $(this).parent().parent().find(".input-item-importe").val((valor_cantidad*valor_precio)-((valor_cantidad*valor_precio)*valor_descuento/100));
    recalculoimporte();
});
$(document).on('change','.input-item-descuento',function(){
    var valor_descuento = $(this).val();
    var valor_precio       = $(this).parent().parent().find(".input-item-precio").val();
    var valor_cantidad  = $(this).parent().parent().find(".input-item-cantidad").val();
    $(this).parent().parent().find(".input-item-importe").val((valor_cantidad*valor_precio)-((valor_cantidad*valor_precio)*valor_descuento/100));
    recalculoimporte();
});
$(document).on('change','.input-item-cantidad',function(){
    var valor_cantidad  = $(this).val();
    console.log(valor_cantidad);
    var valor_descuento = $(this).parent().parent().find(".input-item-descuento").val();
    console.log(valor_descuento);
    var valor_precio       = $(this).parent().parent().find(".input-item-precio").val();
    console.log(valor_precio);
    $(this).parent().parent().find(".input-item-importe").val((valor_cantidad*valor_precio)-((valor_cantidad*valor_precio)*valor_descuento/100));
    recalculoimporte();
});
$(document).on('change','.input-item-importe',function(){
    var valor_cantidad  = $(this).parent().parent().find(".input-item-cantidad").val();
    var valor_precio       = $(this).parent().parent().find(".input-item-precio").val();
    var valor_descuento = (((valor_cantidad*valor_precio)-$(this).val())*100)/(valor_cantidad*valor_precio);
    $(this).parent().parent().find(".input-item-descuento").val(valor_descuento);
    $(this).parent().parent().find(".input-item-importe").val((valor_cantidad*valor_precio)-((valor_cantidad*valor_precio)*valor_descuento/100));
    recalculoimporte();
});
//FUNCIONES
function recalculoimporte(){
    var lineas = $('.registro-items');
    var subtotal = 0.00;
    $.each(lineas,function(){
        if($(this).find('.input-item-importe').prop('disabled') == false){
            subtotal = subtotal + parseFloat($(this).find('.input-item-importe').val());
        }
    });
    if($("#tipo-documento").val() == "factura"){
        $("#subtotal-documento").val(subtotal.toFixed(2));
        $("#irpf-documento").val((subtotal*0.15).toFixed(2));
        $("#iva-documento").val((subtotal*0.21).toFixed(2));
        $("#total-documento").val((subtotal-(subtotal*0.15)+(subtotal*0.21)).toFixed(2));
    }else if($("#tipo-documento").val() == "presupuesto"){
        $("#subtotal-documento").val(subtotal.toFixed(2));
        $("#iva-documento").val((subtotal*0.21).toFixed(2));
        $("#total-documento").val((subtotal+(subtotal*0.21)).toFixed(2));

    }
    
}


