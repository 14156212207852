//Home User
$(document).on('click','.carnet-avatar',function(){
    $("#camara").click();
});
$(document).on('change','#camara',function(){
    $("#formCambioAvatar").submit();
});

$('.home-container').ready(function(){
    infogeneral($("#ano").val());
});
$(document).on('change','#ano',function(){
    infogeneral($(this).val());
});

function infogeneral(ano){
    console.log(ano);
    $.ajax({
        url: '/home/infogeneral/'+ano,
        type: 'GET',
        success: function(response){
            
            $("#cuantos-clientes").html(response.cuantos_clientes);
            $("#total-ingresos").html(response.total_facturado+" €");
            $.each(response.trimestres_facturado,function(index,value){
                $("#"+(index)+"-ingresos").html(index + "Trimestre: " + value+" €");
            });
            $("#total-iva-facturado").html(response.total_iva_facturado+" €");
            $.each(response.trimestres_iva_facturado,function(index,value){
                $("#"+(index)+"-iva-facturado").html(index + "Trimestre: " + value+" €");
            });
            $("#total-irpf-retenido").html(response.total_irpf_retenido+" €");
            $.each(response.trimestres_irpf_retenido,function(index,value){
                $("#"+(index)+"-irpf-retenido").html(index + "Trimestre: " + value+" €");
            });
            $("#total-gastos").html(response.total_gastado+" €");
            $.each(response.trimestres_gastado,function(index,value){
                $("#"+(index)+"-gastos").html(index + "Trimestre: " + value+" €");
            });
            $("#total-iva-devengado").html(response.total_iva_devengado+" €");
            $.each(response.trimestres_iva_devengado,function(index,value){
                $("#"+(index)+"-iva-devengado").html(index + "Trimestre: " + value+" €");
            });
            $("#total-irpf-repercutido").html(response.total_irpf_repercutido+" €");
            $.each(response.trimestres_irpf_repercutido,function(index,value){
                $("#"+(index)+"-irpf-repercutido").html(index + "Trimestre: " + value+" €");
            });
            $("#total-beneficio-neto").html(response.total_neto+" €");
            $.each(response.trimestres_neto,function(index,value){
                $("#"+(index)+"-beneficio-neto").html(index + "Trimestre: " + value+" €");
            });
            $("#total-declaracion-iva").html(response.total_declaracion_iva+" €");
            $.each(response.trimestres_declaracion_iva,function(index,value){
                $("#"+(index)+"-declaracion-iva").html(index + "Trimestre: " + value+" €");
            });
            $("#total-declaracion-irpf").html(response.total_declaracion_irpf+" €");
            $.each(response.trimestres_declaracion_irpf,function(index,value){
                $("#"+(index)+"-declaracion-irpf").html(index + "Trimestre: " + value+" €");
            });
        }
    });
}