//Proveedores
const Choices = require('./choices.js');
const fecha = new Date();
const ultimo_dia_mes = [31,28,31,30,31,30,31,31,30,31,30,31];
$(document).on('click','.abreProveedorModal', function(){
    $("#fichaProveedor").modal("show");
    var id = this.id.substr(10,25);
    $("#_method").val("PUT");
    $("#formProveedor").attr('action',"proveedores/"+id);
    $(".btnDeleteProveedor").css("display","block");
    $(".btnDeleteProveedor").attr('id',"delete-"+id);
    $("#actividades").empty();
    $(".campo").val("");
    $.ajax({
        url: "proveedores/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaProveedorLabel").html("Edición de proveedor "+data['nombre']);
            $.each(data,function(index,value){
                $("#"+index.toLowerCase()).val(value);
            });
        }
    }); 
});
$(document).on('click','.nuevoProveedorModal', function(){
    $("#_method").val("POST");
    $("#formProveedor").attr('action',"proveedores");
    $("#fichaProveedorLabel").html("Creación de nuevo proveedor");
    $(".btnDeleteProveedor").css("display","none");
    $(".campo").val("");    
})
//HERRAMIENTAS CARDS
$(document).on('click','.desplegable',function(){
    var icon = $(this);
    $(".registro-historial-items-"+icon.parent().parent().parent().parent().attr('id').substr(18,100)).toggle();
    $.each(icon.attr('class').split(' '),function(index,value){
        if(value == 'bi-caret-up'){
            icon.addClass('bi-caret-down-fill');
            icon.removeClass('bi-caret-up-fill');
        }
        if(value == 'bi-caret-down'){
            icon.addClass('bi-caret-up-fill');
            icon.removeClass('bi-caret-down-fill');
        }
    });
});

$(document).on('click','.btnDeleteProveedor',function(){
    if(confirm("Estás seguro que deseas eliminar a este proveedor?") == true){
        $("#form"+this.id).submit();
    }
});
$(document).on('click','.historial-proveedor',function(){
    var id = this.id.substr(20,20);
    $.ajax({
        async: false,
        url: "proveedores/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#historialLabel").html("Historial de facturas de "+data['nombre']);
            $("#historial-lista").empty();
            $("#historial-lista").append(`
                <div class="registro-historial" style='position:sticky; top:0; background-color:#fff'> 
                    <div class='d-flex flex-column w-100'>
                        <div class='d-flex flex-row align-items-center w-100'>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'># Factura</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Fecha</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Subtotal</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>IVA</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Total</p>
                            </div>
                            <div style='width:2%'>  
                            </div>
                        </div>
                    </div>
                </div>
            `);
            $.each(data.gastos,function(index,value){
                var html = `
                    <div class="registro-historial" id="registro-historal-`+value['id']+`"> 
                        <div class='d-flex flex-column w-100'>
                            <div class='d-flex flex-row align-items-center w-100'>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['serial']+`</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'><i class="bi bi-calendar-event me-1"></i>`+value['fecha_emision']+`</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['subtotal']+`€</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['iva']+`€</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['total']+`€</p>
                                </div>
                                <div style='width:2%'>
                                    <i class="bi bi-eye-fill ver-gasto"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                `;  
                $("#historial-lista").append(html);
            });
        }
    });
});
$(document).on('click','.subir-gasto',function(){
    var id = this.id.substr(12,20);
    $("#fichaGastoForm").attr('action',"gastos");
    $("#proveedor_id").val(id);
});
$(document).on('click','.ver-gasto',function(){
    var icon = $(this);
    var id = icon.parent().parent().parent().parent().attr('id').substr(18,100);
    window.open("proveedores/gasto/"+id);
});
//FUNCIONES
