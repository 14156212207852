$(document).on('click','.abreGastoModal', function(){
    var id = this.id.substr(6,25);
    $("#_method").val("PUT");
    $("#fichaGastoForm").attr('action',"gastos/"+id);
    $("#btnDeleteDocumento").css("display","block");
    $("#btnDeleteDocumento").attr('onclick',"document.getElementById('delete-"+id+"').submit()");
    $("#container-irpf-documento").show();
    $(".campo").val("");
    $.ajax({
        url: "gastos/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#proveedor_id").val(data['proveedor_id']);
            $("#fichaGastoLabel").html("Edición de gasto");
            //Serial
            $("#serial").val(data['serial']);
            //Fecha
            $("#fecha_emision").val(data['fecha_emision']);
            //Totales
            $("#subtotal").val(data['subtotal']);
            $("#irpf").val(data['irpf']);
            $("#iva").val(data['iva']);
            $("#ratio_iva").val(data['ratio_iva']);
            $("#total").val(data['total']);
        }
    }); 
});



//FUNCIONES
